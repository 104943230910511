<!--

      This view displays the page, which allows to trigger a password reset
      e-mail.

 -->

<template>
  <SignInUpPage action="resetpassword">
    <div class="back-button-area" @click="this.$router.go(-1)">
      <Icon name="foodableArrowBack" />
    </div>

    <div class="signinup-content">
      <div class="text-section">
        <div class="headline">Passwort vergessen?</div>
        <div class="main-text">Keine Sorge. Du erhälst eine E-Mail mit einem Link um dein Passwort zu ändern.</div>
      </div>

      <div class="fields">
        <div class="input-field">
          <Icon name="personOutline" class="icon-input" />
          <ion-input @ionChange="setEmail($event)" placeholder="E-Mail" pattern="email" inputmode="email" type="email" data-cy="email"></ion-input>
        </div>
      </div>

      <div class="btn-primary" type="submit" @click="this.requestPasswordReset()" data-cy="submit">Weiter</div>
    </div>
  </SignInUpPage>
</template>

<script>
import SignInUpPage from '@/components/SignInUpPage.vue'
import { IonInput } from '@ionic/vue'

export default {
  name: 'resetPassword',
  components: {
    SignInUpPage,
    IonInput
  },
  data () {
    return {
      email: null
    }
  },
  methods: {
    goTo (name) {
      this.$store.dispatch('goTo', name)
    },
    setEmail (event) {
      if (event.target.value !== '') {
        this.email = event.target.value
      }
    },
    requestPasswordReset () {
      const details = {
        url: this.$store.state.api.pw.requestreset,
        data: {
          email: this.email
        },
        method: 'POST'
      }
      this.$store.dispatch('apiSend', details).then(result => {
        if (result && result.email && result.email.includes('Enter a valid email address.')) {
          this.$store.dispatch('showNotification', { message: 'Bitte gib eine gültige E-Mail Adresse ein.', type: 'Error' })
        } else if (result && result.email === this.email) {
          this.$store.dispatch('showNotification', { message: 'Wir haben dir eine E-Mail geschickt. Bitte schau auch in deinen Spam Ordner. Öffne den angegebenen Link auf deinem Smartphone, damit du in die App weitergeleitet wirst.', type: 'Success' })
        }
      })
    }
  },
  updated () {
    if (this.$store.state.profile && this.$store.state.profile.email) {
      this.email = this.$store.state.profile.email
    }
  }
}
</script>
